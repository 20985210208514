import React from "react"
import logo from "../assets/images/silta-white-logo.svg"
// import dot from '../assets/images/dot.svg'
// import externalLink from "../assets/images/external-link-purple.svg"
// import externalLinkWhite from "../assets/images/external-link-white.svg"

export default function Footer() {

  const dAppUrl = 'https://app.silta.finance'
  // const siltaWhitePaperUrl = '/whitepaper'

  const scrollDelay = (ms) => {
    return new Promise(res => setTimeout(res, ms));
  }

    const scrollToTop = async () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
    await scrollDelay(800)
  }

  return <>
    <footer className="site-footer" data-scroll-section>
      <div className="container">

        {/* FOOTER MOBILE */}
        <div className="footer-content-mobile">
          <div className="row d-flex align-items-center">
          {/* footer-row */}
              <div className="col-footer-logo col-8">
                <a href="/" className="footer-logo"><img src={logo} alt="Logo"/></a>
              </div>
              <div className="col-2">
                <button id="scroll-to-top" className="btn scroll-to-top" onClick={() => scrollToTop()}>
                    <svg aria-hidden="true" className="progress" width="70" height="70" viewBox="0 0 70 70">
                    <path className="progress__circle" d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z" />
                    <path className="progress__path" d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z" pathLength="1" />
                  </svg>
                    <span className="material-icons">arrow_upward</span>
                  </button>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-footer-nav align-items-end col-12">
              </div>
            </div> */}

            <div className="row mt-5">
              <div className="col-footer-nav col-5">
                <ul className="nav nav-footer">
                <li className="nav-footer-title">Project</li>
                <a className="nav-link" href="/about">About</a>
                <a href="https://siltafinance.teamtailor.com" target="_blank" rel="noreferrer" className="nav-link">Careers</a>
                <a className="nav-link" href="/borrowers/">Borrowers</a>
                </ul>
              </div>
              {/* <div className="col-footer-nav col-5">
                <ul className="nav nav-footer">
                <li className="nav-footer-title">Docs</li>
                 <li>
                  <a className="nav-link" href={siltaWhitePaperUrl} target="_blank" rel="noreferrer">
                  Whitepaper
                  </a>
                </li> 
                </ul>
              </div> */}
              <div className="col-footer-nav col-6 mb-5">
                <ul className="nav nav-footer">
                <li className="nav-footer-title">
                  Community
                  </li>
                  <div className="row">
                    <div className="col-6">

                <li>
                  <a className="nav-link" href="https://twitter.com/silta_fi" target="_blank" rel="noreferrer">
                    Twitter
                    </a>
                  </li>

                <li>
                  <a className="nav-link" href="https://www.linkedin.com/company/silta-finance/" target="_blank" rel="noreferrer">
                  LinkedIn
                  </a>
                  </li>
                  </div>

                  <div className="col-3">

                <li>
                  <a className="nav-link" href="https://medium.com/silta-finance" target="_blank" rel="noreferrer">
                  Medium
                  </a>
                  </li>
                <li>
                  <a className="nav-link" href="https://www.facebook.com/Silta.finance" target="_blank" rel="noreferrer">
                  Facebook
                  </a>
                  </li>
                <li>
                  <a className="nav-link" href="https://www.youtube.com/channel/UCefrSCL16p_NKbYXJfKMHWQ" target="_blank" rel="noreferrer">
                  YouTube
                  </a>
                  </li>
                  </div>
                  </div>
                </ul>
              </div>
            </div>

            <div>
                <div className="footer-copyright d-flex flex-column align-items-center">
                  {/* <div className="white-line" /> */}
                    <p className="copyright-text text-center">
                      Copyright © {new Date().getFullYear()}, Silta Finance AG.<br/>
                      </p>
                      <p className="copyright-text text-center">
                      All rights reserved.<br/>
                      <br/>
                      Silta Finance AG, Bahnhofstrasse 21,<br/>
                      6300 Zug, Switzerland

                    </p>
                    <div className="privacy-links d-flex flex-column align-items-center text-center mt-5">
                      <a href="/terms-of-use" target="_blank" rel="noreferrer">Terms</a>
                      <a href="/privacy-policy" target="_blank" rel="noreferrer">Privacy policy</a>
                      <a href="/privacy-policy#cookies" target="_blank" rel="noreferrer">Cookie policy</a>
                      <a href="/terms-of-use#disclaimers" target="_blank" rel="noreferrer">Legal disclaimer</a>
                    </div>
                  </div>
            </div>
          <div className="footer-disclaimer">
            <p>
              This site is for informational purposes only and is not intended as any kind of investment advice. Use of this site is subject to our <a href="/terms-of-use" target="_blank" rel="noreferrer">terms of use</a> and the general disclaimer set forth therein. We handle your data and protect your privacy in accordance with our <a href="/privacy-policy" target="_blank" rel="noreferrer">privacy policy</a>. For further information, email us at <a href="mailto:contact@silta.finance">contact@silta.finance</a>.
            </p>
          </div>
        </div>

        {/* FOOTER DESKTOP */}

        <div className="footer-content">
          <div className="row">
            <div className="col-footer-logo col-lg-12 col-xl-2">
              <a href="/" className="footer-logo"><img src={logo} alt="Logo"/></a>
            </div>
            <div className="col-md-9 col-lg-9 col-xl-7">
              <div className="row">

            <div className="col-footer-nav col-md-4 col-xs-4 col-lg-3 col-xl-4">
              <ul className="nav nav-footer">
               <li className="nav-footer-title">Project</li>
               <a href="/about" className="nav-link">About</a>
               <a href="https://siltafinance.teamtailor.com" target="_blank" rel="noreferrer" className="nav-link">Careers</a>
               <a href="/borrowers/" className="nav-link">Borrowers</a>
               {/* <span className="badge"> <img src={dot} /> We're hiring</span> */}
              </ul>
            </div>
            {/* <div className="col-footer-nav col-lg-3 col-xl-4 col-4">
               <ul className="nav nav-footer">
               <li className="nav-footer-title">Docs</li>
                <li>
                <a className="nav-link" href={siltaWhitePaperUrl} target="_blank" rel="noreferrer">
                 Whitepaper
                 </a>
                 </li> 
              </ul>
            </div>*/}

            <div className="col-footer-nav col-lg-4 col-md-6 col-xl-3">
               <ul className="nav nav-footer">
               <li className="nav-footer-title">
                 Community
                 </li>
                 <div className="d-xl-block d-lg-flex flex-lg-wrap d-md-flex flex-md-wrap">

               <li className='li-community'>
                <a className="nav-link" href="https://twitter.com/silta_fi" target="_blank" rel="noreferrer">
                  Twitter
                  </a>
                 </li>

               <li className='li-community'>
                <a className="nav-link" href="https://www.linkedin.com/company/silta-finance/" target="_blank" rel="noreferrer">
                 LinkedIn
                 </a>
                 </li>

               <li className='li-community'>
                <a className="nav-link" href="https://medium.com/silta-finance" target="_blank" rel="noreferrer">
                 Medium
                 </a>
                 </li>

               <li className='li-community'>
                <a className="nav-link" href="https://www.youtube.com/channel/UCefrSCL16p_NKbYXJfKMHWQ" target="_blank" rel="noreferrer">
                 YouTube
                 </a>
                 </li>
                 </div>
              </ul>
            </div>
              </div>
            </div>

            <div className="col-footer-nav align-items-end col-3">
              <a href={dAppUrl} target="_blank" rel="noreferrer" className="btn-footer btn-footer-white">
                LAUNCH APP
                <svg className="ml-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.83331 10.1666L10.1666 1.83325M10.1666 1.83325H1.83331M10.1666 1.83325V10.1666" stroke="#5720CD" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </a>

              <button id="scroll-to-top" className="btn scroll-to-top" onClick={() => scrollToTop()}>
                <svg aria-hidden="true" className="progress" width="70" height="70" viewBox="0 0 70 70">
                <path className="progress__circle" d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z" />
                <path className="progress__path" d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z" pathLength="1" />
              </svg>
                <span className="material-icons">arrow_upward</span>
              </button>
            </div>
          </div>
          <div className="footer-copyright">
                <p className="copyright-text">
                  Copyright © {new Date().getFullYear()}, Silta Finance AG.<br/>
                  All rights reserved.<br/>
                  <br/>
                  Silta Finance AG, Bahnhofstrasse 21,<br/>
                  6300 Zug, Switzerland
                </p>
                <div className="privacy-links">
                  <a href="/terms-of-use" target="_blank" rel="noreferrer">Terms</a>
                  <a href="/privacy-policy" target="_blank" rel="noreferrer">Privacy policy</a>
                  <a href="/privacy-policy#cookies" target="_blank" rel="noreferrer">Cookie policy</a>
                  <a href="/terms-of-use#disclaimers" target="_blank" rel="noreferrer">Legal disclaimer</a>
                </div>
              </div>
          <div className="footer-disclaimer">
            <p>
              This site is for informational purposes only and is not intended as any kind of investment advice. Use of this site is subject to our <a href="/terms-of-use" target="_blank" rel="noreferrer">terms of use</a> and the general disclaimer set forth therein. We handle your data and protect your privacy in accordance with our <a href="/privacy-policy" target="_blank" rel="noreferrer">privacy policy</a>. For further information, email us at <a href="mailto:contact@silta.finance">contact@silta.finance</a>.
            </p>
          </div>
        </div>
      </div>
    </footer>
  </>
}