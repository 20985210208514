import React from "react"
// import Header from "./header"
import Footer from "./footer"
import Loadable from '@loadable/component'


const HeaderComponent = Loadable(() => import("./header"))

export default function Layout({ children }) {

  return (
    <>
      <div style={{position: 'fixed', width: '100%', top: 0, left: 0, zIndex: 999}} >
      <HeaderComponent />
      </div>
        {children}
        <Footer />
      </>
    )
}